

export function getCountryFromTimezone(): String{
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; 

    return (timezones[timezone] || "").toLowerCase();
}

const timezones = {
  "Africa/Johannesburg": "ZA",
  "America/Adak": "US",
  "America/Anchorage": "US",
  "America/Araguaina": "BR",
  "America/Bahia": "BR",
  "America/Belem": "BR",
  "America/Boa_Vista": "BR",
  "America/Boise": "US",
  "America/Campo_Grande": "BR",
  "America/Chicago": "US",
  "America/Cuiaba": "BR",
  "America/Denver": "US",
  "America/Detroit": "US",
  "America/Eirunepe": "BR",
  "America/Fortaleza": "BR",
  "America/Indiana/Indianapolis": "US",
  "America/Indiana/Knox": "US",
  "America/Indiana/Marengo": "US",
  "America/Indiana/Petersburg": "US",
  "America/Indiana/Tell_City": "US",
  "America/Indiana/Vevay": "US",
  "America/Indiana/Vincennes": "US",
  "America/Indiana/Winamac": "US",
  "America/Juneau": "US",
  "America/Kentucky/Louisville": "US",
  "America/Kentucky/Monticello": "US",
  "America/Los_Angeles": "US",
  "America/Maceio": "BR",
  "America/Manaus": "BR",
  "America/Menominee": "US",
  "America/Metlakatla": "US",
  "America/New_York": "US",
  "America/Nome": "US",
  "America/Noronha": "BR",
  "America/North_Dakota/Beulah": "US",
  "America/North_Dakota/Center": "US",
  "America/North_Dakota/New_Salem": "US",
  "America/Phoenix": "US",
  "America/Porto_Velho": "BR",
  "America/Recife": "BR",
  "America/Rio_Branco": "BR",
  "America/Santarem": "BR",
  "America/Sao_Paulo": "BR",
  "America/Sitka": "US",
  "America/Yakutat": "US",
  "Antarctica/Macquarie": "AU",
  "Australia/Adelaide": "AU",
  "Australia/Brisbane": "AU",
  "Australia/Broken_Hill": "AU",
  "Australia/Darwin": "AU",
  "Australia/Eucla": "AU",
  "Australia/Hobart": "AU",
  "Australia/Lindeman": "AU",
  "Australia/Lord_Howe": "AU",
  "Australia/Melbourne": "AU",
  "Australia/Perth": "AU",
  "Australia/Sydney": "AU",
  "Europe/Berlin": "DE",
  "Europe/Bucharest": "RO",
  "Europe/Busingen": "DE",
  "Europe/London": "GB",
  GB: "GB",
  "GB-Eire": "GB",
  NZ: "NZ",
  "Pacific/Auckland": "NZ",
  "Pacific/Chatham": "NZ",
  "Pacific/Honolulu": "US",
  "US/Arizona": "US",
  "US/Hawaii": "US",
};