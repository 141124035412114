import { getCookie } from "./util.js"
import {getCountryFromTimezone} from "./lib/timezone/timezone";

import navigateToTranslation from "./navigateToTranslation"

// Has the user chosen a country?
let currentCountry = (getCookie("barnabasfund-org-country") || "").toLowerCase() || getCountryFromTimezone();

const allowedCountries = JSON.parse(document.head.querySelector("meta[name=siteLanguages]").content)

// Only allow certain values of the countries cookie
if (!allowedCountries.includes(currentCountry)) {
  currentCountry = "";
}

if (currentCountry) {

  // Move the user to the correct country-specific page if they landed on the wrong one
  console.log("current country: ", currentCountry)
  const pathCountryIsCorrect = (window.location.pathname.match(/^\/([a-z]{2})\//) && window.location.pathname.match(/^\/([a-z]{2})\//)[1]) === currentCountry
  console.log("pathCountryIsCorrect: ", pathCountryIsCorrect)
  if (!pathCountryIsCorrect) {
    navigateToTranslation(currentCountry)
  }

} 
