
function is404() {
    const mt = document.head.querySelector("meta[name=is404]")
    return mt && mt.content === "true"
}

export default function (country) {

    if (is404()) {
        window.location.href = window.location.origin + window.location.pathname.replace(/^(\/[a-z]{2})?\//, "/" + country + "/")
            + window.location.search + window.location.hash;
        return
    }

    // Get alternate language from link tags
    const link = document.head.querySelector("link[rel=alternate][hreflang=" + country + "]");
    //const href = link ? link.href : 

    if (link) { 
        window.location.href = link.href + window.location.search + window.location.hash;
    }
}